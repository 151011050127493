import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Link from './Link'
import VH from './VH'
import { up, addPagePadding } from '../lib/styles'
import links from '../lib/links'

import logoSrc from '../data/images/logo-white.png'
import menuOpen from '../data/images/menu-open.svg'
import menuClose from '../data/images/menu-close.svg'

import csIcon from '../data/images/cs-icon.jpg'
import enIcon from '../data/images/en-icon.jpg'

const LanguageIcon = styled.div`
  ${({ isActive }) => css`
    width: 55px;
    height: 42px;
    cursor: pointer;
    margin: auto;
    border: 1px solid transparent;
    border-radius: 8px;

    ${isActive
    && css`
      border-color: white;
    `}

    padding: 7.5px;

    img {
      width: 39px;
      height: 26px;
    }
  `}
`

const Wrapper = styled.div`
  ${({ theme: { sizes }, isPinned }) => css`
    width: 100%;
    transition: 0.3s;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
    position: fixed;

    height: ${sizes.thinMenuHeight}px;

    ${up('menu')} {
      background-color: black;

      ${!isPinned
      && css`
        background-color: transparent;

        height: ${sizes.thickMenuHeight}px;
      `}
    }
  `}
`

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;

  padding: 0 30px;
  ${up('menu')} {
    padding: 0 10px;
  }
  ${up(1050)} {
    padding: 0 100px 0 96px;
  }
`

const Logo = styled.img`
  ${({ isPinned }) => css`
    width: 80px;
    height: auto;
    padding: 10px;

    ${up('menu')} {
      width: 110px;

      ${!isPinned
      && css`
        padding: 4px 10px 18px 10px;
      `}
    }
  `}
`

const LanguageSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  position: absolute;
  background-color: transparent;
  width: 100%;
  ${up('menu')} {
    flex-direction: column;
  }
`

const Item = styled.div`
  ${({ theme: { fonts, colors }, isActive }) => css`
    color: #ffffff;
    font-family: ${fonts.hind};
    font-size: 19px;
    font-weight: ${isActive ? '700' : '400'};
    text-transform: uppercase;
    margin-bottom: 10px;

    ${isActive
    && css`
      background: rgba(105, 105, 105, 0.34);
    `}
    padding: 8px 25px;

    &:hover {
      background: rgba(105, 105, 105, 0.34);
    }

    ${up('menu')} {
      margin: 0;
      background: none;
      padding: 0;
      padding: 10px 8px;
      &:hover {
        background: none;
      }
    }
    ${up(950)} {
      padding: 10px;
    }
  `}
`

const ItemsWrapper = styled.div`
  ${({ isOpen, theme: { sizes } }) => css`
    display: ${isOpen ? 'flex' : 'none'};
    position: absolute;
    flex-direction: column;
    top: ${sizes.thinMenuHeight}px;
    left: 0;
    width: 100%;
    background: black;
    z-index: 500;
    padding: 30px 0 20px 0;
    ${addPagePadding()}
    ${up('menu')} {
      background: transparent;
      position: relative;
      top: 0;
      max-width: 786px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
    }
  `}
`

const MenuIcon = styled.img`
  height: auto;
  width: 50px;
  cursor: pointer;
  padding: 10px;
`

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isPinned: false,
      language: 'en',
      isMobileMenu: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.setIsMobileMenu)
    this.setIsMobileMenu()
    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.setIsMobileMenu)
  }

  handleScroll = () => {
    const isPinned = window.scrollY > 0
    this.setState({ isPinned })
  }

  setIsMobileMenu = () => {
    const isMobileMenu = window.innerWidth < 700
    this.setState({ isMobileMenu })
  }

  setLanguage = (language) => {
    console.log('language', language)

    // TODO - otestovat v buildu, přidat do local storage
    this.setState({ language })
  }

  shouldShowLanguageSelect = ({ pathname, key }) => {
    const { isPinned, isMobileMenu } = this.state

    return (
      (!isPinned || isMobileMenu)
      && ([links.CAREER, links.CAREER_CS, `${links.CAREER}/`, `${links.CAREER_CS}/`].includes(
        pathname,
      )
        || isMobileMenu)
      && [links.CAREER, links.CAREER_CS].includes(links[key])
    )
  }

  render() {
    const { pathname } = this.props
    const { isOpen, isPinned, language, isMobileMenu } = this.state

    const MenuItemComponent = isMobileMenu ? Row : Col

    return (
      <Wrapper isPinned={isPinned}>
        {/* <VH show={pathname !== '/'} showAfter="menu"> */}
        <InnerWrapper>
          <Link to={Link.START}>
            <Logo src={logoSrc} className="quit-hp-video" alt="zuri logo" isPinned={isPinned} />
          </Link>
          {/* </VH> */}

          <Gap gap="40px" mobileGap="10px" bp={1060} />

          <Col hideAfter="menu">
            <div onClick={() => this.setState({ isOpen: !isOpen })}>
              {isOpen ? (
                <MenuIcon src={menuClose} alt="close menu" />
              ) : (
                <MenuIcon src={menuOpen} alt="open menu" />
              )}
            </div>
          </Col>

          <ItemsWrapper isOpen={isOpen}>
            {Object.keys(links)
              .filter((key) => key !== 'CAREER_CS')
              .map((key) => (
                <MenuItemComponent key={key}>
                  <Link
                    className="quit-hp-video"
                    to={
                      links[key] === links.CAREER && language === 'cs'
                        ? links.CAREER_CS
                        : links[key]
                    }
                  >
                    <Item
                      className="quit-hp-video"
                      isActive={
                        links[key] === pathname
                        || `${links[key]}/` === pathname
                        || (pathname === links.CAREER_CS && links[key] === links.CAREER)
                      }
                      onClick={() => this.setState({ isOpen: false })}
                    >
                      {key}
                    </Item>
                  </Link>
                  {this.shouldShowLanguageSelect({ pathname, key }) && (
                    <Col position="relative" alignItems="center">
                      <LanguageSelectWrapper>
                        <Link to={links.CAREER_CS} onClick={() => this.setLanguage('cs')}>
                          <LanguageIcon
                            isActive={[links.CAREER_CS, `${links.CAREER_CS}/`].includes(pathname)}
                          >
                            <img src={csIcon} alt="Czech language" />
                          </LanguageIcon>
                        </Link>
                        <Link to={links.CAREER} onClick={() => this.setLanguage('en')}>
                          <LanguageIcon
                            isActive={[links.CAREER, `${links.CAREER}/`].includes(pathname)}
                          >
                            <img src={enIcon} alt="English language" />
                          </LanguageIcon>
                        </Link>
                      </LanguageSelectWrapper>
                    </Col>
                  )}
                </MenuItemComponent>
              ))}
          </ItemsWrapper>
        </InnerWrapper>
      </Wrapper>
    )
  }
}

export default Menu
